<template>
  <div class="set-install-baseinfo">
    <!-- 第一步 -->
    <a-radio-group v-model="installType">
      <a-radio style="width: 160px" v-for="(item, index) in installOptions" :key="index" :value="item">
        <span style="font-size: 16px;color: #333333;">{{ item }}安装</span>
      </a-radio>
    </a-radio-group>
    <div style="height: 12px;"></div>
    <a-radio-group @change="geMasterShell" v-model="osType">
      <a-radio style="width: 160px" v-for="(item, index) in plainOptions" :key="index" :value="item">
        <svg-icon :style="{'font-size': item === 'Linux'? '16px' : '20px'}" :icon-class="item === 'Linux' ? 'linux' : 'windows'" />
        <span style="font-size: 16px;color: #333333;" class="mgl8">{{ item }}主机</span>
      </a-radio>
    </a-radio-group>
    <div v-if="installType == '手动'">
      <div style="margin-top: 24px">
        <div class="tips">请为您的主机中以root身份执行以下脚本：</div>
        <a-textarea v-model="shellDes" placeholder="执行脚本" :rows="8" />
      </div>
      <div style="display: flex; justify-content: flex-end; margin-top: 20px">
        <!-- <a-button @click="copyShell" type="primary">复制</a-button> -->
      </div>
    </div>
    <div v-if="installType == '自动'">
      <a-form :form="form">
        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="服务器IP" :rows="4">
          <a-input v-decorator="[
                        'host',
                        {
                          rules: [{ required: true, message: '请输入服务器IP地址' }],
                        },
                      ]" placeholder="请输入服务器IP地址" />
        </a-form-item>
        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="端口">
          <a-input v-decorator="[
                        'port',
                        {
                          rules: [{ required: true, message: '请输入端口' }],
                        },
                      ]" placeholder="端口" />
        </a-form-item>
        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="SSH账号">
          <a-input v-decorator="[
                        'username',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入SSH账号',
                            },
                          ],
                        },
                      ]" placeholder="请输入SSH账号" />
        </a-form-item>
        <a-form-item label="验证方式" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
          <a-select v-decorator="[
                        'auth_type',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择验证方式',
                            },
                          ],
                        },
                      ]">
            <a-select-option value="password">密码验证</a-select-option>
            <a-select-option value="private_key">密钥验证</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="密码">
          <a-input type="password" v-if="!isShow" v-decorator="['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
          <a-input v-if="isShow" v-decorator="['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
          <a-icon :type="!isShow ? 'eye-invisible':'eye'" @click.stop="isShow=!isShow" style="position: absolute;cursor: pointer;right: 10px;top: 1px;" />
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
    
  <script>
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  name: "SetInstallBaseInfo",
  components: {},
  props: {
    masterNodeName: String
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { auth_type: undefined }),
      plainOptions: ["Linux", "windows"],
      osType: "Linux",
      installType: "手动",
      installOptions: ["自动", "手动"],
      shellDes: "",
      isShow: false,
    };
  },
  watch: {
    masterNodeName :{
      handler(val) {
        if (val) this.geMasterShell()
      }
    }
  },
  mounted() {
    // this.geMasterShell();
    this.init()
  },
  methods: {
    check() {
      const self = this;
      let num = 0;
      let param = {};
      self.form.validateFields((err, values) => {
        param = {
            host: values.host,
            port: values.port,
            username: values.username,
            auth_type: values.auth_type,
          };
          let key = param.auth_type;
          param[key] = values["password"];
        if (err) {
          num++;
        }
      });
      return { flag: num > 0, param, installType: this.installType, osType: this.osType };
    },
    geMasterShell() {
      let obj = {
        type: this.osType,
        name: this.masterNodeName
      };
      this.$axiosGet(`${global.API.geMasterShell}`, obj).then((res) => {
        this.shellDes = res.data;
      });
    },
    copyShell() {
      var oInput = document.createElement("input"); //创建一个input标签
      oInput.value = this.shellDes;
      document.body.appendChild(oInput); //
      oInput.select(); // 模拟鼠标选中
      document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
      oInput.style.display = "none"; //
      this.$message.success(`复制成功`);
    },
    async dispatcherHostAgentCompleted(callback) {
      const params = {
        clusterId: this.clusterId,
      };
      // 等待网络请求结束
      let flag = await this.$axiosPost(
        global.API.dispatcherHostAgentCompleted,
        params
      );
      // 网络请求结束后才执行下边的语句  如果传入的callback方法为空或者没传内容也不会去执行，这样也不会影响此方法在别处的调用
      if (callback) {
        callback(flag);
      }
    },
    nexStep() {
      if (this.installType == "自动") {
        let flag = false
        this.form.validateFields(async (err, value) => {
          if (!err) {
            let obj = {
              host: value.host,
              port: value.port,
              username: value.username,
              auth_type: value.auth_type,
            };
            let key = obj.auth_type;
            obj[key] = value["password"];
           flag = await this.$axiosJsonPost(global.API.creatMaster + '?type=' + this.osType, obj).then(
            (res) => {
              this.$message.success('操作成功')
            }
          )
          }
        });
      }
    },
    init() {
      this.form.getFieldsValue([
        "username",
        "port",
        "host",
        "password",
        "auth_type",
      ]);
      this.form.setFieldsValue({
        username: '',
        port: "",
        password: "",
        host: "",
        auth_type: undefined,
      });
    },
  },
};
</script>
    
<style lang="less" scoped>
.set-install-baseinfo {
  .tips {
    font-size: 16px;
    color: #333333;
    margin-bottom: 1px;
  }
  /deep/.ant-form {
    margin-top: 44px;
  }
  /deep/.ant-form-item {
    margin-bottom: 16px;
  }
  /deep/ .ant-form-item-with-help {
    margin-bottom: 0px;
  }
  /deep/.ant-form-item-label {
    margin-right: 0px;
    label {
      font-size: 14px;
      color: #1d202d;
    }
  }
}
</style>