var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"set-install-baseinfo"},[_c('a-radio-group',{model:{value:(_vm.installType),callback:function ($$v) {_vm.installType=$$v},expression:"installType"}},_vm._l((_vm.installOptions),function(item,index){return _c('a-radio',{key:index,staticStyle:{"width":"160px"},attrs:{"value":item}},[_c('span',{staticStyle:{"font-size":"16px","color":"#333333"}},[_vm._v(_vm._s(item)+"安装")])])}),1),_c('div',{staticStyle:{"height":"12px"}}),_c('a-radio-group',{on:{"change":_vm.geMasterShell},model:{value:(_vm.osType),callback:function ($$v) {_vm.osType=$$v},expression:"osType"}},_vm._l((_vm.plainOptions),function(item,index){return _c('a-radio',{key:index,staticStyle:{"width":"160px"},attrs:{"value":item}},[_c('svg-icon',{style:({'font-size': item === 'Linux'? '16px' : '20px'}),attrs:{"icon-class":item === 'Linux' ? 'linux' : 'windows'}}),_c('span',{staticClass:"mgl8",staticStyle:{"font-size":"16px","color":"#333333"}},[_vm._v(_vm._s(item)+"主机")])],1)}),1),(_vm.installType == '手动')?_c('div',[_c('div',{staticStyle:{"margin-top":"24px"}},[_c('div',{staticClass:"tips"},[_vm._v("请为您的主机中以root身份执行以下脚本：")]),_c('a-textarea',{attrs:{"placeholder":"执行脚本","rows":8},model:{value:(_vm.shellDes),callback:function ($$v) {_vm.shellDes=$$v},expression:"shellDes"}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-top":"20px"}})]):_vm._e(),(_vm.installType == '自动')?_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"服务器IP","rows":4}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'host',
                      {
                        rules: [{ required: true, message: '请输入服务器IP地址' }],
                      },
                    ]),expression:"[\n                      'host',\n                      {\n                        rules: [{ required: true, message: '请输入服务器IP地址' }],\n                      },\n                    ]"}],attrs:{"placeholder":"请输入服务器IP地址"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"端口"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'port',
                      {
                        rules: [{ required: true, message: '请输入端口' }],
                      },
                    ]),expression:"[\n                      'port',\n                      {\n                        rules: [{ required: true, message: '请输入端口' }],\n                      },\n                    ]"}],attrs:{"placeholder":"端口"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"SSH账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'username',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入SSH账号',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'username',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入SSH账号',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"请输入SSH账号"}})],1),_c('a-form-item',{attrs:{"label":"验证方式","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'auth_type',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择验证方式',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'auth_type',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请选择验证方式',\n                          },\n                        ],\n                      },\n                    ]"}]},[_c('a-select-option',{attrs:{"value":"password"}},[_vm._v("密码验证")]),_c('a-select-option',{attrs:{"value":"private_key"}},[_vm._v("密钥验证")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"密码"}},[(!_vm.isShow)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]),expression:"['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]"}],attrs:{"type":"password","placeholder":"请输入密码"}}):_vm._e(),(_vm.isShow)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]),expression:"['password',{ rules: [{ required: true, message: '密码不能为空!' }] }]"}],attrs:{"placeholder":"请输入密码"}}):_vm._e(),_c('a-icon',{staticStyle:{"position":"absolute","cursor":"pointer","right":"10px","top":"1px"},attrs:{"type":!_vm.isShow ? 'eye-invisible':'eye'},on:{"click":function($event){$event.stopPropagation();_vm.isShow=!_vm.isShow}}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }