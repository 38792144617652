<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-16 19:08:06
 * @LastEditTime: 2023-02-16 19:16:21
 * @FilePath: \awx-ui\src\components\empty-host\importHost\waitMasterInstall.vue
-->
<template>
  <div class="wait-master-install">
    <svg-icon icon-class="master-wait" />
    <div class="wait-title">正在等待管理节点的安装</div>
    <div class="wait-desc">管理节点尚未安装完成，您可以稍后继续通过脚本完成安装部署</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.wait-master-install {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  svg {
    font-size: 98px;
  }
  .wait-title {
    font-size: 22px;
    color: #555555;
    margin: 30px 0 45px;
  }
  .wait-desc {
    font-size: 14px;
    color: #888888;
  }
}
</style>