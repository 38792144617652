<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-16 16:33:29
 * @LastEditTime: 2024-01-15 10:47:59
 * @FilePath: \awx-ui\src\components\empty-host\importHost\setNodeName.vue
-->
<template>
  <div class="set-node-name">
    <span>请为您本次接入的管理节点设置一个名称：</span>
    <a-form :form="form">
      <a-form-item label>
        <a-input size="large" v-decorator="['nodeName',{rules: [{ required: true, message: '请输入节点名称' }]}]" placeholder="请输入" />
      </a-form-item>
    </a-form>
  </div>
</template>
      
    <script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  name: "SetNodeName",
  components: {},
  data() {
    return {
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, {}),
    };
  },
  mounted() {
    this.initName()
  },
  methods: {
    initName () {
      const name = 'Endpoit_' + (Math.floor(Math.random() * 90000) + 10000)
      this.form.getFieldsValue(['nodeName'])
      this.form.setFieldsValue({
        nodeName: name
      })
    },
    check() {
      let nodeName = null
      this.form.validateFields((err, value) => {
        if (!err) {
          nodeName = value.nodeName
        }
      });
      return nodeName
    },
    checkAllForm() {
      const self = this;
      let num = 0;
      for (var i = 0; i < self.SERVICENAMES.length; i++) {
        const item = self.SERVICENAMES[i];
        self.$refs[`CommonTemplateRef${item}`][0].form.validateFields(
          (err, values) => {
            console.log(values);
            if (err) {
              self.serviceNameKey = item;
              num++;
            }
          }
        );
        if (num > 0) break;
      }
      return num > 0;
    },
    nexStep() {
      if (this.value2 == "自动") {
        this.form.validateFields((err, value) => {
          if (!err) {
            let obj = {
              host: value.host,
              port: value.port,
              username: value.username,
              auth_type: value.auth_type,
            };
            let key = obj.auth_type;
            obj[key] = value["password"];
            this.$emit("creatMaster", {
              type: "creat",
              params: obj,
            });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
      
  <style lang="less" scoped>
.set-node-name {
  margin-top: 80px;
  span {
    font-size: 16px;
    color: #555555;
    letter-spacing: 0;
    line-height: 24px;
  }
}
/deep/.ant-form {
  margin-top: 10px;
}
/deep/.ant-form-item {
  margin-bottom: 0;
}
/deep/.ant-form-item-label {
  margin-right: 8px;
}
</style>