<template>
  <div class="manual-important">
    <a-form-model :model="form" :layout="'inline'" class="mgb8 form-batch">
      <a-form-model-item label>
        <a-input style="width: 140px; height: 30px" placeholder="主机IP" v-model="form.ip" />
      </a-form-model-item>
      <a-form-model-item label>
        <a-input style="width: 60px; height: 30px" placeholder="端口" v-model="form.port" />
      </a-form-model-item>
      <a-form-model-item label>
        <a-input style="width: 110px; height: 30px" placeholder="用户名" v-model="form.username" />
      </a-form-model-item>
      <a-form-model-item label>
        <a-input style="width: 110px; height: 30px" type="password" placeholder="凭证" v-model="form.password" />
      </a-form-model-item>
      <a-form-model-item label>
        <a-select v-model="form.auth_type" placeholder="认证方式" style="width: 110px">
          <a-select-option v-for="(child) in optionsList('auth_type')" :value="child.value" :key="child.value" :title="child.label">{{child.label}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label>
        <a-select v-model="form.osType" placeholder="操作系统" style="width: 120px">
          <a-select-option v-for="(child) in optionsList('osTyPe')" :value="child.value" :key="child.value" :title="child.label">
            <svg-icon :icon-class="child.label" />
            {{child.label}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <a-button :class="['btn-import', formData.dataSource.length === 0 ? '' : 'right8']" type="primary" @click="add">导入</a-button>
    <a-form-model :model="formData" :ref="`formDataRef`" class="handle-btn-box">
      <a-table :columns="columns" :data-source="formData.dataSource" :scroll="{y: 274}" :pagination="false" :class="[formData.dataSource.length > 0 ? '' : 'no-data-table']">
        <template v-for="col in ['ip', 'port', 'username', 'password', 'auth_type', 'osType']" :slot="col" slot-scope="text, record, index">
          <a-form-model-item :key="col" v-if="['ip', 'port', 'username', 'password'].includes(col)" :prop="`dataSource.${index}.${col}`" :rules="{ required: true, message: '请输入' }">
            <a-input v-if="['ip', 'port', 'username', 'auth_type', 'osType'].includes(col)" style="margin: -5px 0; height: 26px; width: 100%" v-model="record[`${col}`]" @change="e => handleChange(e.target.value, record.key, col)" />
            <a-input v-else type="password" style="margin: -5px 0; height: 26px; width: 100%" v-model="record[`${col}`]" @change="e => handleChange(e.target.value, record.key, col)" />
          </a-form-model-item>
          <a-form-model-item :key="col" v-else :prop="`dataSource.${index}.${col}`" :rules="{ required: true, message: '请选择' }">
            <a-select style="margin: -5px 0; height: 26px; width: 100%" :value="text" @change="e => handleChange(e, record.key, col)">
              <a-select-option v-for="(child) in optionsList(col)" :value="child.value" :key="child.value" style="height: 26px;" :title="child.label">
                <svg-icon v-if="col === 'osType'" :icon-class="child.label" />
                {{child.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a @click="() => cancel(record.key)" class="mgl10">移除</a>
            </span>
          </div>
        </template>
      </a-table>
    </a-form-model>

    <div class="add-new-master">
      <span class="mgr10">
        <a-icon type="info-circle" style="color:#447AF0 ; font-size: 18px; margin-right: 5px" />主机IP支持正则批量导入，如192.198.[1-3]或者192.198.[1,2,3]
      </span>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "主机IP",
    dataIndex: "ip",
    width: 144,
    scopedSlots: { customRender: "ip" },
  },
  {
    title: "端口",
    width: 68,
    dataIndex: "port",
    scopedSlots: { customRender: "port" },
  },
  {
    title: "用户名",
    width: 118,
    dataIndex: "username",
    scopedSlots: { customRender: "username" },
  },
  {
    title: "凭证",
    dataIndex: "password",
    width: 118,
    scopedSlots: { customRender: "password" },
  },
  {
    title: "认证方式",
    width: 118,
    dataIndex: "auth_type",
    scopedSlots: { customRender: "auth_type" },
  },
  {
    title: "操作系统",
    dataIndex: "osType",
    width: 128,
    scopedSlots: { customRender: "osType" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i.toString(),
//     ip: `Edrward ${i}`,
//     port: 32,
//     username: `London Park no. ${i}`,
//     osType: "windows",
//     authType: "",
//     password: "ssss",
//   });
// }
export default {
  props: {
    masterId: String,
  },
  data() {
    return {
      cacheData: [],
      formData: {
        dataSource: [],
      },
      columns,
      editingKey: "",
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      form: {
        // username: "",
        // auth_type: undefined,
        // osType: undefined,
        // password: "",
        // port: "22",
        // ip: "",
        username: "",
        auth_type: undefined,
        osType: undefined,
        password: "",
        port: "",
        ip: "",
      },
      authTypeList: [
        {
          label: "密码",
          value: "password",
        },
        {
          label: "密钥",
          value: "private_key",
        },
      ],
      osTypeList: [
        {
          label: "Linux",
          value: "Linux",
        },
        {
          label: "Windows",
          value: "Windows",
        },
      ],
    };
  },
  computed: {},
  methods: {
    check() {
      const self = this;
      let num = 0;
      if (this.formData.dataSource.length === 0) {
        this.$message.warning("请至少导入一台主机");
        return { flag: true, dataSource: this.formData.dataSource };
      }
      self.$refs.formDataRef.validate(async valid => {
        if (!valid) {
          num ++ 
        }
      })
      let params = _.cloneDeep(this.formData.dataSource)
      params.forEach(item => { delete item.key})
      return { flag: num > 0, dataSource: params };
    },
    add() {
      if (!this.form.ip) return this.$message.warning("请输入主机IP");
      if (!this.form.port) return this.$message.warning("请输入端口");
      if (!this.form.username) return this.$message.warning("请输入用户名");
      if (!this.form.password) return this.$message.warning("请输入凭证");
      if (!this.form.auth_type) return this.$message.warning("请选择认证方式");
      if (!this.form.osType) return this.$message.warning("请选择操作系统");
      // 这种属于批量导入的逻辑
      if (this.form.ip.includes("[") && this.form.ip.includes("]")) {
        let str = this.form.ip;
        const ipBefore = this.form.ip.substring(0, str.indexOf("["));
        str = str.substring(str.indexOf("[") + 1, str.indexOf("]"));
        if (str.includes("-")) {
          const ipArr = str.split("-");
          if (ipArr.length > 1) {
            for (
              let i = Math.min(ipArr[0], ipArr[1]);
              i < Math.max(ipArr[0], ipArr[1]) + 1;
              i++
            ) {
              this.formData.dataSource.push({
                key: ipBefore + Math.random(),
                ip: ipBefore + i,
                master: this.masterId,
                groupId: "default",
                name: ipBefore + i,
                port: Number(this.form.port),
                username: this.form.username,
                osType: this.form.osType,
                auth_type: this.form.auth_type,
                password: this.form.password,
              });
            }
          }
        }
        if (str.includes(",")) {
          const ipArr = str.split(",");
          if (ipArr.length > 0) {
            for (
              let i = 0;
              i < ipArr.length;
              i++
            ) {
              if (!isNaN(Number(ipArr[0]))) {
                this.formData.dataSource.push({
                  key: ipBefore + Math.random(),
                  ip: ipBefore + ipArr[i],
                  master: this.masterId,
                  groupId: "default",
                  name: ipBefore + ipArr[i],
                  port: Number(this.form.port),
                  username: this.form.username,
                  osType: this.form.osType,
                  auth_type: this.form.auth_type,
                  password: this.form.password,
                });
              }
            }
          }
        }
        return false;
      }
      this.formData.dataSource.push({
        key: this.form.ip + Math.random(),
        ip: this.form.ip,
        port: Number(this.form.port),
        username: this.form.username,
        osType: this.form.osType,
        auth_type: this.form.auth_type,
        password: this.form.password,
        master: this.masterId,
        groupId: "default",
        name: this.form.ip,
      });
    },
    onSubmit() {
      console.log("submit!", this.form);
    },
    optionsList(key) {
      const list = key === "auth_type" ? this.authTypeList : this.osTypeList;
      return list;
    },
    handleChange(value, key, column) {
      const newData = [...this.formData.dataSource];
      const target = newData.find((item) => key === item.key);
      if (target) {
        target[column] = value;
        this.formData.dataSource = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.find((item) => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.formData.dataSource];
      const newCacheData = [...this.cacheData];
      const target = newData.find((item) => key === item.key);
      const targetCache = newCacheData.find((item) => key === item.key);
      if (target && targetCache) {
        delete target.editable;
        this.formData.dataSource = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.formData.dataSource];
      this.formData.dataSource = newData.filter((item) => key !== item.key);
    },
  },
};
</script>
<style lang="less" scoped>
.manual-important {
  position: relative;
  /deep/ .ant-select-selection--single {
    height: 26px;
  }
  /deep/ .ant-select-selection__rendered {
    line-height: 26px;
  }
  /deep/ .ant-table-thead > tr > th {
    border-bottom: none;
  }
  /deep/ .ant-form-inline .ant-form-item {
    margin-right: 8px;
  }
  /deep/ .ant-table-tbody > tr > td {
    padding: 6px 4px 5px;
  }
  .no-data-table {
    /deep/ .ant-table-header.ant-table-hide-scrollbar {
      overflow-y: auto !important;
    }
  }
  .form-batch {
    /deep/ .ant-select-selection--single {
      height: 30px;
    }
    /deep/ .ant-select-selection__rendered {
      line-height: 30px;
    }
  }
  .editable-row-operations a {
    margin-right: 8px;
    color:#3974f4;
  }
  .add-new-master {
    margin: 10px 0 0 0;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    &-btn {
      color: #447af0;
      display: inline-block;
      cursor: pointer;
    }
  }
  .btn-import {
    position: absolute;
    width: 62px;
    height: 30px;
    border-radius: 3px;
    top: 2px;
    right: 0px;
  }
  .right8 {
    right: 8px;
  }
  .handle-btn-box {
    margin-top: 0;
      /deep/ .ant-form-explain {
        display: none;
      }
  }
}
</style>
