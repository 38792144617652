<template>
  <div class="select-import-method">
    <div class="tips">请选择导入方式：</div>
    <div v-if="importMethodsList.length > 0" class="methods-list">
      <div v-for="(item, index) in importMethodsList" :key="index" :class="['select-import-method-item', selectMethod === item.value && 'select-import-method-item-selected']" @click="onSelect(item)">
        <div :class="['radio-circle', selectMethod === item.value ? 'radio-circle-selected' : '']">
          <a-icon v-if="selectMethod === item.value" class="radio-circle-small" type="check" />
        </div>
        <div class="methos-svg">
          <svg-icon :icon-class="item.svg" />
        </div>
        <div class="select-import-method-item-name">{{ item.label }}</div>
      </div>
    </div>
    <div class="add-new-master">
      <span v-if="selectMethod === 'manual'" class="mgr10">直接填写主机的IP、操作系统等信息，将主机导入到管理节点中进行管理</span>
      <span v-else>您可以从已有的资产管理系统中生成主机模板文件，调整成符合条件的格式后，上传到管理节点来实现批量导入<a class="mgl12" @click="downloadFile()">
        下载模板文件范例
        </a></span>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      importMethodsList: [
        {
          label: "手工输入主机ip",
          value: "manual",
          svg: "master-manual",
        },
        {
          label: "模板文件批量导入",
          value: "batch",
          svg: "master-import",
        },
      ],
      selectMethod: "manual",
    };
  },
  mounted() {
    this.$emit('changeOptions', { label: "手工导入", status: "notSet" }, this.selectMethod)
  },
  methods: {
    onSelect(item) {
      this.selectMethod = item.value;
      const options = item.value === 'batch' ? { label: "模板文件导入", status: "notSet" } : { label: "手工导入", status: "notSet" }
      this.$emit('changeOptions', options, this.selectMethod)
    },
    downloadFile(resUrl) {
        let apiAjax = resUrl ? '/awx' + resUrl : global.API.hostsImportTemplate
        this.$axiosGet(apiAjax, {}).then((res) => {
        let blob = new Blob([res.data]);//response.data为后端传的流文件
        let downloadFilename = !resUrl ?  "主机组" + "模板.xls" : "导入结果.xls";//设置导出的文件名
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //兼容ie浏览器
          window.navigator.msSaveOrOpenBlob(blob, downloadFilename)
        }else {
        //谷歌,火狐等浏览器
          let url = window.URL.createObjectURL(blob);
          let downloadElement = document.createElement("a");
          downloadElement.style.display = "none";
          downloadElement.href = url;
          downloadElement.download = downloadFilename;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(url);
          this.$nextTick(() => {
            this.$message.success("下载成功！");
          });
        }
      });
      return;
    },
  },
  
};
</script>
  <style lang="less" scoped>
.select-import-method {
  .tips {
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .methods-list {
    display: flex;
  }
  &-item {
    position: relative;
    width: 220px;
    height: 240px;
    background: #ffffff;
    border: 1px solid #cfd5de;
    box-shadow: 0px 4px 14px 0px rgba(209, 219, 240, 0.3);
    border-radius: 4px;
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 33px;
    .methos-svg  svg{
        font-size: 64px;
        margin-bottom: 24px;
    }
    &:hover, &-selected {
      border: 1px solid #5a8cf9;
    }
    .radio-circle {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid rgba(177, 184, 194, 1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-small {
        color: #fff;
        font-size: 10px;
      }
      &-selected {
        background: @primary-color;
      }
    }
    &-name {
      margin-left: 12px;
      font-size: 16px;
      color: #333333;
    }
  }
  .add-new-master {
    margin: 10px 0 0 0;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    &-btn {
      color: #447af0;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>