<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-02-16 16:28:50
 * @FilePath: \awx-ui\src\components\empty-host\importHost\understandDemand.vue
   了解需求
-->
<template>
  <div class="understand-demand">
    <div class="tips">需要在目标网络内选择一台主机作为宿主机来安装管理节点，即可将目标网络内所有主机接入到门户，宿主机的选择请遵循以下原则：</div>
    <div class="demand-intro">
      <div class="demand-intro-left">
        <svg-icon icon-class="master-node"></svg-icon>
        <div>管理节点</div>
      </div>
      <div class="demand-intro-right">
        <div v-for="(item, index) in configList" :key="index">
          <span class="green-point"></span>
          <span class="config-item">{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      configList: [
        "宿主机和其他主机处于同一个局域网内，且能够和其他主机沟通局域网互通；",
        "宿主机无需具备公网IP，但必须要能够访问公网；",
        "宿主机拥有大于100M的可用磁盘空间，以及64M以上的可用内存；",
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
  <style lang="less" scoped>
.understand-demand {
  .tips {
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .demand-intro {
    display: flex;
    align-items: center;
    margin-top: 48px;
    &-left {
      margin-right: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        font-size: 58px;
      }
      div {
        font-size: 14px;
        color: #555555;
      }
    }
    &-right {
      .green-point {
        width: 8px;
        height: 8px;
        background: #46c017;
        border-radius: 50%;
        margin-right: 12px;
        display: inline-block;
      }
      .config-item {
        display: inline-block;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>