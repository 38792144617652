<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-05 14:51:38
 * @LastEditTime: 2023-02-23 14:29:31
 * @FilePath: \awx-ui\src\components\empty-host\importHost\batchImport.vue
-->
<template>
    <div class="upload-file">
      <div class="flex-upload">
        <a-upload name="file" :multiple="false" :showUploadList="false" @change="handleChange" :accept="fileType" :remove="handleRemove" :beforeUpload="beforeUpload">
          <a-button style="width: 116px" type="primary">
            选择模板文件
          </a-button>
        </a-upload>
        <a-button class="mgl12" style="width: 116px" @click="downloadFile()">
          模板文件格式
        </a-button>
        <div v-if="resUrl" class="center-footer-options mgl16">
          <div class="center-footer-option">
            <svg-icon icon-class="file-result"></svg-icon>
            <span style="margin: 0 8px;">总数</span>
            <span>{{resResult.total}}</span>
          </div>
          <div class="center-footer-option">
            <svg-icon icon-class="file-success"></svg-icon>
            <span style="margin: 0 8px;">成功</span>
            <span>{{resResult.success}}</span>
          </div>
          <div class="center-footer-option">
            <svg-icon icon-class="file-error"></svg-icon>
            <span style="margin: 0 8px;">失败</span>
            <span>{{resResult.fail}}</span>
          </div>
        </div>
      </div>
      <!-- <div v-if="resUrl">导入结果：<span>{{resMsg}}</span> <span class="failed-url mgl10 download-template" @click="downloadFile(resUrl)">查看失败原因</span></div> -->
      <a-table
          class="opt-table table-15 mgt20"
          :columns="columns"
          :loading="loading"
          :dataSource="dataSource"
          :rowKey="(record, index)=>index"
        ></a-table>
      <!-- <div class="footer">
        <a-button class="mgr10" type="primary" @click="hostsImport">导入</a-button>
        <a-button @click="handleCancel">取消</a-button>
      </div> -->
    </div>
  </template>
  <script>
  export default {
    props: {
      callBack: Function
    },
    data() {
      return {
        loading: false,
        headers: {
          authorization: "authorization-text",
        },
        resResult: {
          success: 0,
          total: 0,
          fail: 0
        },
        fileType: ".xls, .xlsx, .csv",
        fileList: [],
        resUrl: false,
        resMsg: '',
        dataSource: [],
        columns: [
        {
          title: "主机名称",
          key: "name",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "操作系统",
          key: "osType",
          dataIndex: "osType",
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span>{
               text
              }</span>;
          },
        },
        {
          title: "IP",
          key: "IP",
          dataIndex: "IP",
          customRender: (text, row, index) => {
            return <span>{text}</span>;
          },
        },
        {
          title: "错误原因",
          key: "result",
          dataIndex: "result",
          customRender: (text) => {
            return <span>{text || '-'}</span>;
          },
        },
      ],
      };
    },
    methods: {
      downloadFile(resUrl) {
        let apiAjax = resUrl ? '/awx' + resUrl : global.API.hostsImportTemplate
        this.$axiosGet(apiAjax, {}).then((res) => {
          let blob = new Blob([res.data]);//response.data为后端传的流文件
          let downloadFilename = !resUrl ?  "主机组" + "模板.xls" : "导入结果.xls";//设置导出的文件名
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //兼容ie浏览器
            window.navigator.msSaveOrOpenBlob(blob, downloadFilename)
          }else {
          //谷歌,火狐等浏览器
            let url = window.URL.createObjectURL(blob);
            let downloadElement = document.createElement("a");
            downloadElement.style.display = "none";
            downloadElement.href = url;
            downloadElement.download = downloadFilename;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(url);
            this.$nextTick(() => {
              this.$message.success("下载成功！");
            });
          }
        });
        return;
      },
      handleCancel() {
        this.$destroyAll();
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      },
      beforeUpload(file) {
        // if (file.size > 10 * 1024 * 1000) {
        //   this.$message.error("文件上传最大仅支持10M");
        //   return;
        // }
        this.fileList = [file];
        return false;
      },
      handleChange(e) {
        // if(e.file.size > (10*1024*1000)){
        //   return
        // }
        this.file = e.file;
        this.hostsImport()
      },
      hostsImport() {
        const self = this
        self.loading = true
        let formdata = new FormData();
        formdata.append("file", this.file);
        this.$axiosFormData(global.API.hostsImport, formdata).then((res) => {
          self.loading = false
          if ([200, 201, 202, 204].includes(res.status)) {
            if (res.data.code === 200) {
              self.resMsg = res.data.msg
              if (Number(res.data.data.total) > 0) self.resUrl = true
              self.resResult = {
                ...res.data.data,
                success: Number(res.data.data.success),
                total: Number(res.data.data.total),
                fail: Number(res.data.data.fail),
              }
              const arr = []
              res.data.data.hosts.forEach(item => {
                arr.push({
                  name: item['主机名'] || '-',
                  result: item.result === 'true' ? '' : item.result,
                  osType: item['系统类型']|| '-',
                  IP: item.IP || '-',
                  status: item.result === 'true'
                })
              })
              self.dataSource = arr
            } else {
              self.$message.error(res.data.msg)
              self.resUrl = false
            }
          } 
        }).catch(res => {
          self.loading = false
        })
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .upload-file {
    .flex-upload {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .download-template {
      color: @primary-color;
      cursor: pointer;
    }
    .footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
    .import-result {
      font-size: 16px;
      color: #333;
    }
    .center-footer-options {
      display: flex;
      align-items: center;

      .center-footer-option {
        position: relative;
        & + .center-footer-option {
          margin-left: 40px;

          &::before {
            content: "";
            position: absolute;
            left: -20px;
            width: 1px;
            height: 18px;
            background: #e2e4e5;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  </style>