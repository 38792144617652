<template>
  <div class="master-node">
    <a-spin :spinning="spinning">
      <div class="tips">请选择管理节点： </div>
      <div v-if="masterNodesList.length > 0" class="nodes-list">
        <div v-for="(item, index) in masterNodesList" :key="index" :class="['master-node-item', item.status !== 'online' && 'item-disabled']" @click="onSelect(item)">
          <div :class="['radio-circle', selectedMasterId === item.id ? 'radio-circle-selected' : '']">
            <div v-if="selectedMasterId === item.id" class='radio-circle-small'></div>
          </div>
          <div class="master-node-item-name">
            <svg-icon class="mgr8" :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'" :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"></svg-icon>
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="add-new-master">
        <span v-if="masterNodesList.length > 0" class='mgr10'>您也可以：</span>
        <span class="add-new-master-btn" @click="addNew">接入新的管理节点</span>
      </div>
    </a-spin>
  </div>
</template>
<script>
export default {
  props: {
    clusterId: [Number, String],
  },
  data() {
    return {
      masterNodesList: [],
      spinning: false,
      selectedMasterId: null
    };
  },
  watch: {
    clusterId: {
      handler(val) {
        if (val) {
          this.selectedMasterId = val
          this.$emit('getMasterId', val)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getMasterList();
  },
  methods: {
    onSelect (item) {
      if (item.status !== 'online') return false
      this.selectedMasterId = item.id
      this.$emit('getMasterId', item.id)
    },
    addNew () {
      const stepsOptions = {
        title: "安装管理节点",
        desc: "在目标网络中安装管理节点",
        stepList: [
          { label: "了解安装需求", status: "setting" },
          { label: "设置节点名称", status: "notSet" },
          { label: "设置安装信息", status: "notSet" },
          { label: "安装", status: "notSet" },
        ],
      }
      this.$emit('addNewMaster', stepsOptions)
    },
    getMasterList() {
      this.spinning = true;
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            this.masterNodesList = res.data.results;
          } else {
            this.masterNodesList = [];
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.master-node {
  .tips {
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .nodes-list {
    height: 264px;
    width: 770px;
    max-height: 264px;
    border: 1px solid rgba(207,213,222,1);
    border-radius: 4px;
    overflow-y: auto;
  }
  &-item {
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #D8D8D8;
    padding: 16px;
    display: flex;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      border-bottom: 1px solid #D8D8D8;
    }
    &:hover {
      background: #e6e8ed;
    }
    .radio-circle {
      width: 17px;
      height: 17px;
      background: #FFFFFF;
      border: 1px solid rgba(177,184,194,1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 1px;
      &-small {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: relative;
        left: -1px;
        z-index: 1;
        background: #FFFFFF;
      }
      &-selected {
        background: @primary-color;
      }
    }
    &-name {
      margin-left: 12px;
      font-size: 16px;
      color: #333333;
    }
  }
  .item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    &:hover {
      background: #fff;
    }
    .master-node-item-name {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .add-new-master {
    margin: 10px 0 0 0;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    &-btn {
      color: #447AF0;
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>